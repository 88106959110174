import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, createUrlTreeFromSnapshot, Router, RouterStateSnapshot, UrlSerializer} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {User} from '../services/auth/userEntity';
import {RouterHelper} from '../util/router-helper';
import {selectUser} from './auth-store/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private readonly user$: Observable<User | null>;

  constructor(
    private store: Store,
    private router: Router,
    private urlSerializer: UrlSerializer
  ) {
    this.user$ = this.store.pipe(select(selectUser), take(1));
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.user$.pipe(
      map(user => {
        if (user) {
          return true;
        } else {
          if (next.data.secureLinkTokenAllowed) {
            return !!next.queryParams[RouterHelper.QUERY_PARAM_SECURE_LINK_TOKEN];
          }
          // we want to redirect to the cancelled route:
          return this.router.parseUrl('/auth/login?redirect=' + encodeURIComponent(this.urlSerializer.serialize(createUrlTreeFromSnapshot(next, ['.']))));
        }
      })
    );
  }
}
